<template>
    <ListTracksPage :datatable-config="this.datatableConfig" :get-tracks-function="getTracksFunction" export-route-name="export-app-tracks"/>
</template>

<script>

import TrackService from '@/modules/Apps/Services/TrackService'
import ListTracksPage from '@/modules/Shared/Components/tracks/ListTracksPage'

export default {
    name: 'app-tracks',
    components: {
      ListTracksPage
    },
    data () {
        return {
            datatableConfig: [
                { 'type': 'image', 'field': 'thumbnail', head: this.$t("tracks.tracks-list.thumbnail"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'username', head: this.$t("tracks.tracks-list.user"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'date', head: this.$t("tracks.tracks-list.date"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'duration', head: this.$t("tracks.tracks-list.duration"), 'searchable': false, 'sorteable': false },
                { 'type': 'text', 'field': 'distance', head: this.$t("tracks.tracks-list.distance"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'max_speed', head: this.$t("tracks.tracks-list.max_speed"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'ascent', head: this.$t("tracks.tracks-list.ascent"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'descent', head: this.$t("tracks.tracks-list.descent"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'city', head: this.$t("tracks.tracks-list.city"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'country', head: this.$t("tracks.tracks-list.country"), 'searchable': true, 'sorteable': false },
                { 'type': 'text', 'field': 'resortname', head: this.$t("tracks.tracks-list.resort"), 'searchable': true, 'sorteable': false },
                { 'type': 'actions', 'field': 'actions', head: this.$t("apps.photos.actions"), 'searchable': false, 'sorteable': false }
            ],
            getTracksFunction: TrackService.getTracks
        }
    }
}
</script>
